<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="contactFormCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <FormTemplateModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></FormTemplateModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.status="{ item }">
            {{ $t("STATUSES." + statuses.pages.page[item.status].value) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import FormTemplateModalForm, {
  initialFormData,
} from "./FormTemplateModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "contactForms.contactForm.";

export default {
  name: "FormTemplates",
  components: { FormTemplateModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.FORM_TEMPLATES"),
      search: "",
      routePath: "/cms/contactForms/FormTemplates/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("FORM_INPUT_NAMES.name"),
          value: "name",
        },
        { text: this.$t("TABLE.status"), value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters(["contactFormCollection", "statuses"]),

    endPoint() {
      return (
        "contactForms/admin/" + SiteService.getActiveSiteId() + "/contactForm/"
      );
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchContactForm"]),
    fetchModel() {
      return this.fetchContactForm();
    },

    handleClickItem(item) {
      if (!this.dialogDelete) {
        this.$router.push(this.routePath + item.id);
      }
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
